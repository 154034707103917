/* eslint-disable */

import { ready } from 'jquery';
import lottieWeb from 'lottie-web';
import UIkit from 'uikit';

var animation = new Array();

function loadBMAnimation(element,i) {
  var $animation_params = {
    name: 'animation '+i,
    container: element,
    renderer: "svg",
    loop: Boolean($(element).data('loop')),
    autoplay: Boolean($(element).data('autoplay')),
    path: $(element).data('path')
  }
  if ($(element).attr('data-preserveaspectratio')) { $animation_params['rendererSettings'] = { preserveAspectRatio:$(element).data('preserveaspectratio') } }
  animation[i] = lottieWeb.loadAnimation($animation_params);

  var util = UIkit.util;
  var el = util.$(element);
  UIkit.scrollspy(el, { repeat: true });
  util.on(el,'inview', function(){
    console.log('TEST1');
    setTimeout(function () {
      animation[i].play();
    }, 1000);
  });

  if(Boolean($(element).data('repeat'))){
    util.on(el,'outview', function(){
      animation[i].stop();
    });
  }
}

$(function() {

  var element = document.getElementsByClassName("js_animation");

  for (var i = 0; i < element.length; i++) {
    loadBMAnimation(element[i],i);
  }

  function isElementInMiddleOfScreen($element, offsetPercentage = 0.40) {
    const windowHeight = $(window).height();
    const scrollPosition = $(window).scrollTop();
    const elementOffset = $element.offset().top;
    const elementHeight = $element.outerHeight();

    const elementMiddlePosition = elementOffset + elementHeight / 2;
    const middleOfScreen = scrollPosition + windowHeight / 2;

     // Calculate the offset value to be used as a threshold
    const offsetValue = windowHeight * offsetPercentage;

    return Math.abs(middleOfScreen - elementMiddlePosition) <= offsetValue;
  }

  function spyscrollMiddle() {

    const $targets = $('[data-spyscroll-middle]:not(.uk-active)');

    $targets.each(function() {
      const $this = $(this);
      const isMiddle = isElementInMiddleOfScreen($this);

      $this.toggleClass('uk-active', isMiddle);
      $this.toggleClass('uk-scrollspy-inview', isMiddle);
    });
  }

  // BIG TEXT VIDEO
    const spyscrollMiddleElement = $('[data-spyscroll-middle]:not(.uk-active)');
    if (spyscrollMiddleElement.length > 0){
      spyscrollMiddle();
    }
  $(window).on('scroll', function() {
    if (spyscrollMiddleElement.length > 0){
      spyscrollMiddle();
    }
  });


  const sensitivity = 450; // Adjust this value to control the sensitivity/magnetic strength

  $('.vy_cta_floatingimages_item_magnet').each(function() {
    const item = $(this);

    $(document).on('mousemove', function(e) {
      const mouseX = e.pageX;
      const mouseY = e.pageY;

      const itemX = item.offset().left + item.width() / 2;
      const itemY = item.offset().top + item.height() / 2;

      const distanceX = mouseX - itemX;
      const distanceY = mouseY - itemY;

      const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

      if (distance < sensitivity) {
        const offsetX = - (distanceX / sensitivity) * 25; // Inverse the sign to move away from the cursor
        const offsetY = - (distanceY / sensitivity) * 25; // Inverse the sign to move away from the cursor

        item.css('transform', `translate(${offsetX}px, ${offsetY}px)`);
      } else {
        item.css('transform', 'translate(0, 0)');
      }
    });
  });

});
