/* eslint-disable */
import UIkit from 'uikit';

$(function(){
  // FILTERS
  $('.vy_products_filter_reset .uk-button').click(function(e) {
    console.log('clear test1');
    e.preventDefault();
    $('.vy_products_tools input').val('');
    $('.vy_products_tools select').val('');
    $('.vy_products_tools').removeClass('searching');
    $('.vy_products_tools select.first-choice').removeClass('first-choice');
    $('.vy_filters_products_dropdown a').removeClass('uk-active').removeClass('uk-disable');
    $('.vy_products_filter_reset').addClass('uk-hidden');
    $('.vy_products_tools select option').each(function() {
      $(this).prop('disabled', false);
    });
    $('.vy_products_tools').trigger('submit');
    $('.vy_products_filter').each(function(){
      var default_title = $(this).find('.vy_filters_products_dropdown li:first-child a').text();
      $(this).find('button.vy_filter .text').text(default_title);
    });
  });
  $('.vy_products_tools select').change(function(){
    if (!$('.vy_products_tools.searching').length){
      $('.vy_products_tools').addClass('searching');
      $(this).addClass('first-choice');
    }
    $('.vy_products_tools').trigger('submit');
  });
  $('.vy_products_tools').on('submit',function(e){
    e.preventDefault();
    const toolsContainer = $(this);

    const anyNotEmpty = toolsContainer.find('input, select').filter(function() {
      return $(this).val().trim() !== '';
    }).length > 0;
    if (anyNotEmpty) {
      toolsContainer.addClass('searching');
      $('.vy_products_filter_reset').removeClass('uk-hidden');
    } else {
      toolsContainer.removeClass('searching');
      $('.vy_products_filter_reset').addClass('uk-hidden');
      // $('.vy_filters_products_dropdown a').removeClass('uk-active').removeClass('uk-disable');
      $('.vy_products_tools select option').each(function() {
        $(this).prop('disabled', false);
      });
    }

    ajax_filter_products('1', toolsContainer);
  });
  $('.vy_filters_jobs_select').change(function(){
    ajax_filter_job(1);
  });
  $('.vy_filters_blogs_select').change(function(){
    ajax_filter_blog(1);
  });
  $('.vy_filters_search_form').submit(function(e){
    ajax_filter_blog(1);
    // $('.vy_filters_search_input').blur();
    e.preventDefault();
  });
  // $('.vy_filters_search_input').blur(function(e){
  //     console.log('search unfocus');
  //     ajax_filter_blogs(1);
  // });
  $('.vy_filters_search_button').click(function(e){
    ajax_filter_blog(1);
  });

  $('.vy_filters_products_dropdown a').click(function(e){
    $(this).parents('.vy_filters_products_dropdown').find('a').removeClass('uk-active');
    $(this).addClass('uk-active');

    var name = $(this).data('name');
    var slug = $(this).data('slug');
    var id = $(this).data('id');
    $('#'+id+' .text').text(name);
    $(this).parents('.vy_products_filter').find('select[name="'+id+'"] option[value="'+slug+'"]').prop('selected', true).trigger('change');
    if ($(this).parents('.uk-dropdown.vy_filters_dropdown').length){
      UIkit.dropdown( $(this).parents('.uk-dropdown.vy_filters_dropdown')).hide(false);
    }

    e.preventDefault();
  });
  $('.vy_filters_products_dropdown a.uk-active').each(function(){
    var name = $(this).data('name');
    var slug = $(this).data('slug');
    var id = $(this).data('id');
    $('#'+id+' .text').text(name);
    $(this).parents('.vy_products_filter').find('select[name="'+id+'"] option[value="'+slug+'"]').prop('selected', true);
  });


  $('.vy_filters_blogs_dropdown a').click(function(e){
    $('.vy_filters_blogs_dropdown a').removeClass('uk-active');
    $(this).addClass('uk-active');

    var name = $(this).data('name');
    var slug = $(this).data('slug');
    var id = $(this).data('id');
    $('#'+id+' .text').text(name);
    $('.vy_filters_blogs_select[name="'+id+'"] option[value="'+slug+'"]').prop('selected', true).trigger('change');
    if ($(this).parents('.uk-dropdown.vy_filters_dropdown').length){
      UIkit.dropdown( $(this).parents('.uk-dropdown.vy_filters_dropdown')).hide(false);
    }
    e.preventDefault();
  });
  $(document).on('click', '.vy_blog_listing_item_extra_category', function(e) {
    $('.vy_filters_blogs_dropdown a').removeClass('uk-active');
    var dropdown_item = $('.vy_filters_blogs_dropdown a[data-slug="' + $(this).data('slug') + '"]');
    $(dropdown_item).addClass('uk-active');

    var name = $(dropdown_item).data('name');
    var slug = $(dropdown_item).data('slug');
    var id = $(dropdown_item).data('id');
    $('#' + id + ' .text').text(name);
    $('.vy_filters_blogs_select[name="' + id + '"] option[value="' + slug + '"]').prop('selected', true).trigger('change');
    if ($(dropdown_item).parents('.uk-dropdown.vy_filters_dropdown').length) {
        UIkit.dropdown($(dropdown_item).parents('.uk-dropdown.vy_filters_dropdown')).hide(false);
    }
    UIkit.scroll().scrollTo('.vy_blog');
    // $('.vy_banner_std_content_bottom').scrollTop(0);
    e.preventDefault();
  });

  $('.vy_filters_jobs_dropdown a').click(function(e){
    $('.vy_filters_jobs_dropdown a').removeClass('uk-active');
    $(this).addClass('uk-active');
    // $('.vy_filters_blogs_select')
    var name = $(this).data('name');
    var slug = $(this).data('slug');
    var id = $(this).data('id');
    $('.vy_filters_jobs_radio input[data-slug="'+slug+'"').prop('checked', true);
    $('#'+id+' .text').text(name);
    $('.vy_filters_jobs_select[name="'+id+'"] option[value="'+slug+'"]').prop('selected', true).trigger('change');
    if ($(this).parents('.uk-dropdown.vy_filters_dropdown').length){
      UIkit.dropdown( $(this).parents('.uk-dropdown.vy_filters_dropdown')).hide(false);
    }
    // $('.vy_filters_reset').addClass('uk-active');
    e.preventDefault();
  });

  $('.vy_filters_jobs_radio input[type="radio"]').change(function(e) {
    var name = $(this).parent('label').text().trim();
    var slug = $(this).data('slug');
    var id = $(this).data('id');

    $('.vy_filters_jobs_dropdown a').removeClass('uk-active');
    $('.vy_filters_jobs_dropdown a[data-slug="'+slug+'"]').addClass('uk-active');
    $('#'+id+' .text').text(name);

    $('.vy_filters_jobs_select[name="' + id + '"] option[value="' + slug + '"]').prop('selected', true).trigger('change');
    // e.preventDefault();
  });

  $('.vy_filters_recipes_select').change(function(){
    ajax_filter_recipes(1);
  });
  $('.vy_filters_recipes_search_form').submit(function(e){
    ajax_filter_recipes(1);
    e.preventDefault();
  });
  $('.vy_filters_recipes_search_button').click(function(e){
    ajax_filter_recipes(1);
  });

  $('.vy_filters_deces_search_button').click(function(e){
    ajax_filter_deces(1);
  });

  $('.vy_filters_deces_search_form').submit(function(e){
    ajax_filter_deces(1);
    e.preventDefault();
  });

  $('.vy_filters_recipes_dropdown a').click(function(e){
    $('.vy_filters_recipes_dropdown a').removeClass('uk-active');
    $(this).addClass('uk-active');
    // $('.vy_filters_recipes_select')
    var name = $(this).data('name');
    var slug = $(this).data('slug');
    var id = $(this).data('id');
    $('#'+id+' .text').text(name);
    $('.vy_filters_recipes_select[name="'+id+'"] option[value="'+slug+'"]').prop('selected', true).trigger('change');
    if ($(this).parents('.uk-dropdown.vy_filters_dropdown').length){
      UIkit.dropdown( $(this).parents('.uk-dropdown.vy_filters_dropdown')).hide(false);
    }
    e.preventDefault();
  });

  //PAGINATION
  $(document).on( "click",'.woocommerce-pagination a.page-numbers', function(e) {
    $(this).addClass('uk-active');
    var url = $(this).attr('href');
    var res = url.split("page/");
    if(res.length < 2) res = url.split('paged=');

    ajax_filter_products(res[1]);
    UIkit.scroll().scrollTo('.vy_products_container');

    e.preventDefault();
  });
  $(document).on( "click",'.vy_pager_products a.page-numbers', function(e) {
    $(this).addClass('uk-active');
    var url = $(this).attr('href');
    var res = url.split("page/");
    if(res.length < 2) res = url.split('paged=');

    ajax_filter_products(res[1]);
    UIkit.scroll().scrollTo('.vy_products_container');

    e.preventDefault();
  });

  $(document).on( "click",'.vy_pager_blog a.page-numbers', function(e) {
    $(this).addClass('uk-active');
    var url = $(this).attr('href');
    var res = url.split("page/");
    if(res.length < 2) res = url.split('paged=');


    UIkit.scroll().scrollTo('.vy_blog');

    ajax_filter_blog(res[1]);

    e.preventDefault();
  });

  $(document).on( "click",'.vy_pager_recipes a.page-numbers', function(e) {
    $(this).addClass('uk-active');
    var url = $(this).attr('href');
    var res = url.split("page/");
    if(res.length < 2) res = url.split('paged=');

    ajax_filter_recipes(res[1]);

    e.preventDefault();
  });

  $(document).on( "click",'.vy_pager_deces a.page-numbers', function(e) {
    $(this).addClass('uk-active');
    var url = $(this).attr('href');
    var res = url.split("page/");
    if(res.length < 2) res = url.split('paged=');

    ajax_filter_deces(res[1]);

    e.preventDefault();
  });

});

function ajax_filter_products(paged){
  var formData = $('.vy_products_tools').serialize();




  var selected_filter = {};
  // var paged = 1;

  $('.filters__used').empty();

  if($('select[name="vy_filters_jobs_branches"]').val() != '') selected_filter['branch'] = $('select[name="vy_filters_jobs_branches"]').val();
// console.log(selected_filter['branch']);
  $('.vy_products').addClass('is-loading');
  $('.vy_products_listing').remove();

  // console.log(formData);
  // var urlParams = new URLSearchParams(window.location.search);
  //  var urlData = urlParams.toString();

  var data = {
    action: 'filter_products',
    data: formData,
    afp_nonce: afp_vars.afp_nonce,
    filters: selected_filter,
    paged: paged,
  };

  history.pushState(data, '', '?' + formData);

  $.getJSON( afp_vars.afp_ajax_url, data, function(response) {
    if( response ) {
      if ($('.vy_products_tools.searching').length){
        $('.vy_products_tools select').each(function() {
          const selectElement = $(this);
          if (selectElement.hasClass('first-choice')) {
            return true;
          }
          const attributeName = selectElement.attr('name');
          const availableOptions = response[attributeName];

          selectElement.find('option').each(function() {
            const optionValue = $(this).val();
            // console.log('Option element:', this);
            // console.log('optionValue:', optionValue);

            if (optionValue !== '' && !availableOptions.includes(optionValue)) {
              $(this).prop('disabled', true);
              $('a[data-slug='+optionValue+']').addClass('uk-disable');
            } else {
              $(this).prop('disabled', false);
              if(optionValue !== ''){
                $('a[data-slug='+optionValue+']').removeClass('uk-disable');
              }
            }
          });
          var total_options = selectElement.find('option').length;
          var total_options_disable = selectElement.find('option:disabled').length;
          if((total_options - 1) == total_options_disable){
            $('#innerId').siblings('vy_filter').addClass('uk-disable');
            selectElement.prop('disabled', true);
          }else{
            selectElement.prop('disabled', false);
            $('#innerId').siblings('vy_filter').removeClass('uk-disable');
          }
        });
      }else{
        $('.vy_products_tools select').each(function() {
          $(this).prop('disabled', false);
        });
      }
      // console.log(response);
      $('.vy_products_listing_pager').remove();
      $('.vy_products_filter_title_result_nb').text(response.result_total );
      $('.woocommerce-pagination').remove();
      $('.vy_products_listing_loading').after( response.html );
      $('.vy_products').removeClass('is-loading');
    };
  });
}
function ajax_filter_blog($paged){
  var selected_filter = {};
  var search = '';
  var paged = $paged;

  $('.filters__used').empty();
  search = $('.vy_filters_search_input').val();
  if($('select[name="vy_filters_blog_categories"]').val() != '') selected_filter['category'] = $('select[name="vy_filters_blog_categories"]').val();

  $('#vy_filters_blog_categories').addClass('vy_ajax_loading');
  // $('.vy_blog_listing').addClass('vy_ajax_loading');
  $('.vy_blog').addClass('is-loading');
  $('.vy_blog_listing_items_wrapper .vy_blog_listing_items').remove();

  // console.log(search);

  var data = {
    action: 'filter_blogs', // function to execute
    afp_nonce: afp_vars.afp_nonce, // wp_nonce
    filters: selected_filter, // selected tag
    paged: paged, // selected tag
    // post_id: $('.c-block-_units').attr('data-url'),
    // icl_current_language: $('#icl_current_language').val(),
    search: search, // selected tag
  };


  $.post( afp_vars.afp_ajax_url, data, function(response) {
    if( response ) {

      $('.vy_blog_listing_items_wrapper').empty();
      $('.vy_blog_listing_items_wrapper').append( response );
      // $('.vy_blog_listing').removeClass('vy_ajax_loading');
      $('#vy_filters_blog_categories').removeClass('vy_ajax_loading');
      $('.vy_blog').removeClass('is-loading');

    };
  });

}

function ajax_filter_job($paged){
  var selected_filter = {};
  var paged = $paged;

  $('.filters__used').empty();

  if($('select[name="vy_filters_jobs_branches"]').val() != '') selected_filter['branch'] = $('select[name="vy_filters_jobs_branches"]').val();

  $('.vy_jobs').addClass('is-loading');
  // var count_currentItems = $('.vy_jobs_items .vy_jobs_item').length;
  // var loadingContainer = $('.vy_jobs_items_loading');
  // var itemToDuplicate = $('.vy_jobs_items_loading .vy_jobs_item');

  // console.log(count_currentItems);

  // $('.vy_jobs_items_loading').empty();

  // for (var i = 0; i < count_currentItems; i++) {
  //   loadingContainer.append(itemToDuplicate.clone());
  // }

  $('.vy_jobs_items').empty();

  var data = {
    action: 'filter_jobs',
    afp_nonce: afp_vars.afp_nonce,
    filters: selected_filter,
    paged: paged,
  };


  $.post( afp_vars.afp_ajax_url, data, function(response) {
    if( response ) {

      $('.vy_jobs').removeClass('is-loading');
      $('.vy_jobs_items').append( response );
      // $('.vy_jobs_items').removeClass('vy_ajax_loading');


    };
  });

}

function ajax_filter_recipes($paged){
  var selected_filter = {};
  var search = '';
  var paged = $paged;

  $('.filters__used').empty();
  search = $('.vy_filters_recipes_search_input').val();
  if($('select[name="vy_filters_recipes_categories"]').val() != '') selected_filter['category'] = $('select[name="vy_filters_recipes_categories"]').val();

  $('.vy_archive_listing').addClass('vy_ajax_loading');

  // console.log(search);

  var data = {
    action: 'filter_recipes', // function to execute
    afp_nonce: afp_vars.afp_nonce, // wp_nonce
    filters: selected_filter, // selected tag
    paged: paged, // selected tag
    // post_id: $('.c-block-_units').attr('data-url'),
    // icl_current_language: $('#icl_current_language').val(),
    search: search, // selected tag
  };


  $.post( afp_vars.afp_ajax_url, data, function(response) {
    if( response ) {

      $('.vy_archive_listing_wrapper').empty();
      $('.vy_archive_listing_wrapper').append( response );
      $('.vy_archive_listing').removeClass('vy_ajax_loading');

    };
  });
}


function processAjaxData(urlPath){
  window.history.pushState("","", urlPath);
}

function ajax_filter_deces($paged){
  var selected_filter = {};
  var search = '';
  var paged = $paged;
  var urlPath =  window.location.origin + window.location.pathname;
  var urlSearch = '';
  var urlEtab = '';
  // var urlTime = '';
  // var urlPage = '';

  $('.filters__used').empty();
  search = $('.vy_filters_deces_search_input').val();
  if(search != '' ){
    urlSearch = 'search=' +  search;
  }

  if( $('select[name="etab"]').val() != '' && $('select[name="etab"]').val() != 0){
    selected_filter['etab'] = $('select[name="etab"]').val();
    urlEtab = 'etab=' + selected_filter['etab'];
  };

  // if($('select[name="time"]').val() != '30jours'){
  //   selected_filter['time'] = $('select[name="time"]').val();
  //   urlTime = 'time=' + selected_filter['time'];
  // };

  processAjaxData(urlPath);

  var urlFinal = urlPath;

  // if(urlFinal.includes('/page/')){
  //   var urlSplit = urlFinal.split('/page/');
  //   urlFinal = urlSplit[0];
  // }

  // if(paged > 1 ){
  //   urlPage = '?paged=' + paged;
  // }

  // if(urlPage != ''){
  //   urlFinal += urlPage;
  // }

  if(urlSearch != ''){
    var delimiter = '?';
    // if(urlPage != ''){
    //   delimiter = '&';
    // }
    urlFinal += delimiter + '' + urlSearch;
  }
  if(urlEtab != ''){
    var delimiter = '?';
    // if(urlPage != ''){
    //   delimiter = '&';
    // }
    if(urlSearch != ''){
      delimiter = '&';
    }
    urlFinal += delimiter + '' + urlEtab;
  }
  // if(urlTime != ''){
  //   var delimiter = '?';
  //   // if(urlPage != ''){
  //   //   delimiter = '&';
  //   // }
  //   if(urlSearch != ''){
  //     delimiter = '&';
  //   }
  //   if(urlEtab != ''){
  //     delimiter = '&';
  //   }
  //   urlFinal += delimiter + '' + urlTime;
  // }

  processAjaxData(urlFinal);

  $('body').addClass('vy_ajax_loading');



  var data = {
    action: 'filter_deces', // function to execute
    afp_nonce: afp_vars.afp_nonce, // wp_nonce
    filters: selected_filter, // selected tag
    paged: paged, // selected tag
    // post_id: $('.c-block-_units').attr('data-url'),
    // icl_current_language: $('#icl_current_language').val(),
    search: search, // selected tag
  };


  // console.log(data);

  $.get( afp_vars.afp_ajax_url, data, function(response) {
    if( response ) {
      console.log(response);
      $('.vy_archive_deces_listing_wrapper').remove();
      $('.vy_archive_deces_nopost').remove();
      $('main').append( response );
      $('body').removeClass('vy_ajax_loading');

    };
  });
}

function ajax_filter(){


    var data = {
        action: 'filter_directory', // function to execute
        afp_nonce: afp_vars.afp_nonce, // wp_nonce
        // filters: selected_filter, // selected tag
        // paged: paged, // selected tag
        // post_id: $('.c-block-_units').attr('data-url'),
        // icl_current_language: $('#icl_current_language').val(),
        // search: search, // selected tag
    };


    $.post( afp_vars.afp_ajax_url, data, function(response) {
        if( response ) {



        };
    });

}

