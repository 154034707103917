/* eslint-disable */


// Import Highway
import Highway from '@dogstudio/highway';

 // Import Transitions
import Fade from '../util/transitions/fade';

// Import Renderers
import DefaultRenderer from '../util/renderer/default-renderer';
import UIkit from 'uikit';
import Map from '../routes/map';

import '../routes/ajax';

$('.uk-slider-nav a').attr('target','_self');

export default {
  init() {
    $.fn.animateNumbers = function(stop, commas, duration, ease) {
      return this.each(function() {
          var $this = $(this);
          var isInput = $this.is('input');
          var start = parseInt(isInput ? $this.val().replace(/,/g, "") : $this.text().replace(/,/g, ""));
          var regex = /(\d)(?=(\d\d\d)+(?!\d))/g;
          commas = commas === undefined ? true : commas;

          // number inputs can't have commas or it blanks out
          if (isInput && $this[0].type === 'number') {
              commas = false;
          }

          $({value: start}).animate({value: stop}, {
              duration: duration === undefined ? 1000 : duration,
              easing: ease === undefined ? "swing" : ease,
              step: function() {
                  isInput ? $this.val(Math.floor(this.value)) : $this.text(Math.floor(this.value));
                  if (commas) {
                      isInput ? $this.val($this.val().replace(regex, "$1,")) : $this.text($this.text().replace(regex, "$1,"));
                  }
              },
              complete: function() {
                  if (parseInt($this.text()) !== stop || parseInt($this.val()) !== stop) {
                      isInput ? $this.val(stop) : $this.text(stop);
                      if (commas) {
                          isInput ? $this.val($this.val().replace(regex, "$1,")) : $this.text($this.text().replace(regex, "$1,"));
                      }
                  }
              }
          });
      });
    };

    function updateGesTotal() {
      var totalValue = $('#form_ges #field_totalresult').val();
      var formattedValue = numberWithCommas(totalValue);

      $('#form_ges #field_totalformated').val(formattedValue);
      $('#form_ges .vy_gesform_resultco2').animateNumbers(totalValue);
    }
    function updateItems() {
      console.log('TEST GES updateItems');
      $('#form_ges .frm_repeat_sec').each(function() {
        var resultFieldId = $(this).find('[id*="field_gesresult"]').attr('id');
        var resultValue = $('#' + resultFieldId).val();

        $(this).find('.vy_gesform_itemresult_text').each(function() {
          $(this).text(numberWithCommas(resultValue));
        });
      });
    }

    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    if($('#form_ges').length){
      updateGesTotal();
      updateItems();

      $('#form_ges #field_totalresult').on('change', updateGesTotal);
      $('#form_ges').on('change', '[id*="field_gesresult"]', updateItems);
    }

    function setCookie(cookieName, cookieValue, expirationDays = 30) {
      const expirationDate = new Date();
      expirationDate.setMonth(expirationDate.getMonth() + expirationDays);

      const cookieString = `${cookieName}=${encodeURIComponent(cookieValue)};expires=${expirationDate.toUTCString()};path=/`;
      document.cookie = cookieString;
    }

    Map.init();
    if ( ! String.prototype.getDecimals ) {
      String.prototype.getDecimals = function() {
        var num = this,
          match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if ( ! match ) {
          return 0;
        }
        return Math.max( 0, ( match[1] ? match[1].length : 0 ) - ( match[2] ? +match[2] : 0 ) );
      }
    }

    if($('.vy_splitting_word_wrap').length){
      $('.vy_splitting_word_wrap').on('transitionend webkitTransitionEnd oTransitionEnd', function() {
        // console.log('word-wrap');
        $(this).parents('.vy_splitting_word').css({"overflow": "initial"});
      });
    }

    // if($('.vy_products_item_term_list_more_btn').length){
      $(document).on('click', '.vy_products_item_term_list_more_btn', function() {
        console.log('test1');
        $(this).parents('.vy_products_item_term_list').find('.vy_products_item_term_list_hidden').removeClass('uk-hidden');
        $(this).remove();
      });
    // }
    if($('#form_formulairevoeuxdesympathie').length){
      $('.message_propose select').change(function(){
        // ajax_populate_message(1);

        var value = $(this).val();
        var changed = false;

        $.each(populate_message.sympathie, function(index, item) {
          // do something with `item` (or `this` is also `item` if you like)

          if(index.toString() === value){
            $('.message_sympathie textarea').val( item.message );
            changed = true;
          }
        });

        if(!changed) $('.message_sympathie textarea').val( "" );

      });
    }
    if($('#field_posttype_job').length){
        var jobID = $('.vy_job').attr('data-id');
        $('#field_posttype_job').val(jobID).change();
    }
    if($('#field_posttype_branch').length){
        var branchID = $('.vy_job').attr('data-branch');
          $("#field_posttype_branch").val(branchID).change();
    }

    $(document).on('click', '.vy_branch_contact_item .vy_button .uk-button', function() {
      if($('#field_field_representative').length){
        var emailID = $(this).parents('.vy_branch_contact_item').data("email");
        $('#field_field_representative').val(emailID).change();
      }
    })


    $('.vy_team_member').each(function(){
      $(this).mousemove(function(e) {
        $(this).find($('.vy_team_member_photo')).css({
          left: e.clientX + 25,
          top: e.clientY
        }).addClass('uk-active');
      });
      $(this).mouseout(function(e) {
        $(this).find($('.vy_team_member_photo')).removeClass('uk-active');
      });
    });

    $(document).on('click', '.vy_products_item_btn_quote .vy_button', function() {
      const partNumber = $(this).parents('.vy_products_item').find('.vy_products_item_title > span').text();
      $('.vy_form #field_part_number').val(partNumber);
    });

    if($('.single-product').length){
      var partNumber = $('.vy_product_title').text();
      $('.frm_forms #field_part_number').val(partNumber);
    }

    if($('.wpml-ls-item.menu-item-has-children').length){
      $('.wpml-ls-item.menu-item-has-children > a').click(function(e) {
        e.preventDefault();
      });
    }


    // Quantity "plus" and "minus" buttons
    $( document.body ).on( 'click', '.plus, .minus', function() {

      var $qty        = $( this ).closest( '.quantity' ).find( '.qty'),
        currentVal  = parseFloat( $qty.val() ),
        max         = parseFloat( $qty.attr( 'max' ) ),
        min         = parseFloat( $qty.attr( 'min' ) ),
        step        = $qty.attr( 'step' );
      // Format values
      if ( ! currentVal || currentVal === '' || currentVal === 'NaN' ) currentVal = 0;
      if ( max === '' || max === 'NaN' ) max = '';
      if ( min === '' || min === 'NaN' ) min = 0;
      if ( step === 'any' || step === '' || step === undefined || parseFloat( step ) === 'NaN' ) step = 1;

      // Change the value
      if ( $( this ).is( '.plus' ) ) {
        if ( max && ( currentVal >= max ) ) {
          $qty.val( max );
        } else {
          $qty.val( ( currentVal + parseFloat( step )).toFixed( step.getDecimals() ) );
        }
      } else {
        if ( min && ( currentVal <= min ) ) {
          $qty.val( min );
        } else if ( currentVal > 0 ) {
          $qty.val( ( currentVal - parseFloat( step )).toFixed( step.getDecimals() ) );
        }
      }

      // Trigger change event
      $qty.trigger( 'change' );
    });

    $(document).ready(function(){
      $(document).on('click', '.vy_branch_contact_item .uk-button[href="#contactform"]', function(){
        $('#field_field_representative').val($(this).parents('.vy_branch_contact_item').data('email'));
      });

      if ($("section").hasClass("vy_voeux_print")){
        // $('#printf')[0].contentWindow.print()
        window.print()
      return false;
      }
    });

    $(document).on('mouseenter', '.vy_link_cover', function () {
      if ($(this).parents('.vy_link_cover_wrapper').length) {
        var $parent = $(this).parents('.vy_link_cover_wrapper');
      }else{
        var $parent = $(this).parent();
      }
      $parent.find(".uk-button").addClass('uk-active');
      $parent.addClass('uk-transition-active uk-active');
    }).on('mouseleave', '.vy_link_cover', function () {
      if ($(this).parents('.vy_link_cover_wrapper').length) {
        var $parent = $(this).parents('.vy_link_cover_wrapper');
      }else{
        var $parent = $(this).parent();
      }
      $parent.find(".uk-button").removeClass('uk-active');
      $parent.removeClass('uk-transition-active uk-active');
    }).on('focus', '.vy_link_cover', function () {
      if ($(this).parents('.vy_link_cover_wrapper').length) {
        var $parent = $(this).parents('.vy_link_cover_wrapper');
      }else{
        var $parent = $(this).parent();
      }
      $parent.find(".uk-button").addClass('uk-active');
      $parent.addClass('uk-transition-active uk-active');
    }).on('blur', '.vy_link_cover', function () {
      if ($(this).parents('.vy_link_cover_wrapper').length) {
        var $parent = $(this).parents('.vy_link_cover_wrapper');
      }else{
        var $parent = $(this).parent();
      }
      $parent.find(".uk-button").removeClass('uk-active');
      $parent.removeClass('uk-transition-active uk-active');
    });
    $( ".uk-offcanvas-bar a" ).unbind('click').on( "click",function(){
      //.uk-offcanvas-bar
      UIkit.offcanvas('.uk-offcanvas.uk-open').hide();
    });

    $(function() {
      // match width
      function matchWidthToMax(parent) {
        const selector = parent.data('uk-width-match');
        const elements = parent.find(selector);
        let maxWidth = 0;

        elements.each(function() {
          const width = $(this).outerWidth();
          maxWidth = Math.max(maxWidth, width);
        });

        elements.outerWidth(maxWidth);
      }

      const WidthMatchElement = $('[data-uk-width-match]');
      WidthMatchElement.each(function() {
        matchWidthToMax($(this));
      });
      $(window).on('resize', function() {
        WidthMatchElement.each(function() {
          matchWidthToMax($(this));
        });
      });

      //share fb
      $('.fb-share').click(function(e) {
        e.preventDefault();
        window.open($(this).attr('href'), 'fbShareWindow',
          'height=450, width=550, top=' + ($(window).height() / 2 - 275) +
          ', left=' + ($(window).width() / 2 - 225) +
          ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        return false;
      });

      $('[data-addclass]').click(function(e) {
        e.preventDefault();
        $('[data-addclass]').removeClass('uk-active');
        $(this).addClass('uk-active');

        var addClass = $(this).data('addclass');
        var target = $(this).data('target');
        var removeClass = $(this).data('removeclass');

        setCookie('products_list_style',addClass)

        $(target).addClass(addClass);
        $(target).removeClass(removeClass);
      });
      if ( $( "#vy_header_global_alert" ).length ) {
        var globalAlertSeen = parseInt(sessionStorage.getItem("globalAlertSeen"));
        var global_alert = $('#vy_header_global_alert');

        console.log(globalAlertSeen);
        console.log(global_alert);


        if(globalAlertSeen != true){
          console.log('in');
          global_alert.removeClass('uk-hidden');
        }else{

          UIkit.alert(global_alert).close();
        }
        $('.vy_header_global_alert_close').click(function(){
          if (window.sessionStorage) {
            var dropselvalue = 1;
            sessionStorage.setItem("globalAlertSeen", dropselvalue);
            globalAlertSeen = parseInt(sessionStorage.getItem("globalAlertSeen"));
          }
        });
      }
      if ( $( "#vy_popup" ).length ) {
        var now = new Date()
        var globalPopupSeenStr = sessionStorage.getItem("globalPopupSeen");

        var global_popup = $('#vy_popup');

        if(globalPopupSeenStr){
          var globalPopupSeen = JSON.parse(globalPopupSeenStr);
          var globalPopupSeenDate = new Date(globalPopupSeen.expiry);
          if (now.setDate(now.getDate()) > globalPopupSeenDate.setDate(globalPopupSeenDate.getDate())) {
            sessionStorage.removeItem("globalPopupSeen");
            UIkit.modal(global_popup).show();
          }
        }else{
          UIkit.modal(global_popup).show();
        }

        $('.vy_popup_close').click(function(){
          if (window.sessionStorage) {
            var date = new Date();
            date.setDate(date.getDate() + 7);
            var dropselvalue = 1;
            const item = {
              value: dropselvalue,
              expiry: date,
            }
            sessionStorage.setItem("globalPopupSeen", JSON.stringify(item));
            globalPopupSeen = parseInt(sessionStorage.getItem("globalPopupSeen"));
          }
        });
      }


      UIkit.util.on('.vy_navbar_ecom_search_drop', 'show', function () {
        $('.vy_main_content').toggleClass('uk-active');
      });
      UIkit.util.on('.vy_navbar_ecom_search_drop', 'hide', function () {
        $('.vy_main_content').toggleClass('uk-active');
      });

      if($('.vy_banner_slideshow').length){
        UIkit.util.on('.vy_banner_slideshow > .uk-slideshow', 'itemshow', function (e) {
          if($(e.target).find('.vy_banner_slideshow_content_wrapper').hasClass('uk-light')){
            $('.vy_banner_slideshow_dotnav').addClass('uk-light');
          }else{
            $('.vy_banner_slideshow_dotnav').removeClass('uk-light');
          }
        });
      }
      if($('.vy_banner_hero_item').length){
        UIkit.util.on('.vy_banner_hero > .uk-slider', 'itemshow', function (e) {
          if($(e.target).hasClass('uk-light')){
            $('.vy_banner_hero_dotnav').addClass('uk-light');
          }else{
            $('.vy_banner_hero_dotnav').removeClass('uk-light');
          }
        });
      }
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

  },
};

// Get IE or Edge browser version
var version = detectIE();
var using_IE = false;

if (version === false) {
  console.log('IE/Edge');
} else if (version >= 12) {
  console.log('Edge ' + version);
} else {
  console.log('IE ' + version);
  using_IE = true;

  setTimeout(function(){
    $('.home .vy_loading').fadeOut();
  }, 4000);
}
// add details to debug result
console.log (window.navigator.userAgent);

// Call Highway.Core once.
if($('.transition').length != 0  && using_IE == false){
  const H = new Highway.Core({
    transitions: {
      default: Fade
    },
    renderers: {
      name: DefaultRenderer,
      //home: TimeOut
    }
  });
  H.on('NAVIGATE_END', ({ location }) => {
    // Check Anchor
    if (location.anchor) {
      // Get element
      const el = document.querySelector(location.anchor);

      if (el) {
        // Scroll to element
        window.scrollTo(el.offsetLeft, el.offsetTop);

      }
    }
  });
}


function detectIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}
